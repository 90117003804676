<template>
  <!-- layout-container lesson 15 -->
  <div class="lesson-23-container">
    <div class="display-page" v-if="pageType === 0">
      <template
        v-for="(item, key, index) in componentsList"
        class="display-page"
      >
        <keep-alive :key="index + 'keep'">
          <transition name="slide-fade" :key="index">
            <component :key="index" :is="item" v-if="index + 1 === pageNum">
            </component>
          </transition>
        </keep-alive>
      </template>
    </div>
    <div class="display-page" v-if="pageType === 1">
      <SyllabusPageA />
    </div>
    <div class="display-page test" v-if="pageType === 2">
      <WhiteBoard />
    </div>
    <div class="whiteBoard-box" v-if="globalWhiteB">
      <!-- 白板部分 -->
      <WhiteBoard :canvasHeight="cvHeight" :canvasWidth="cvWidth" />
    </div>
  </div>
</template>
<script>
import { lessonMixin } from "@/mixins/lessonDefault.js";
import SyllabusPageA from "./3_SyllabusPage_A.vue";
import WhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue";

import LessonPage from "./1_Lesson.vue";
import RankMapPageOne from "./2_RankMapPageOne.vue";
import TargetPage from "./3_Target.vue";
import OpeningSongPage from "./4_OpeningSongPage.vue";
import IntroductionPage from "./5_Introduction.vue";
import StoryPageOne from "./6_StoryPageOne.vue";
import RecognitionPage_d from "./7_RecognitionPage_d.vue";
import RecognitionPage_t from "./8_RecognitionPage_t.vue";
import BingoGame from "./9_BasketballGame.vue";
import StoryPageTwo from "./10_StoryPageTwo.vue";
import RecognitionPage_n from "./11_RecognitionPage_n.vue";
import RecognitionPage_l from "./12_RecognitionPage_l.vue";
import BasketballGame from "./13_BasketballGame.vue";
import PinyinWordsCard from "./14_PinyinWordsCard.vue";
import StoryPageThree from "./15_StoryPageThree.vue";
import TigerMachineGame from "./16_TigerMachineGame.vue";
import RobotJumpGame from "./17_RobotJumpGame.vue";
import PinyinMoveGame from "./18_PinyinMoveGame.vue";
import WritingPage from "./19_WritingPage.vue";
import PuzzleGame from "./20_PuzzleGame.vue";
import PinyinSummary from "./21_PinyinSummary.vue";
import RankMapPageTwo from "./22_RankMapPageTwo.vue";
import ByePage from "./23_ByePage.vue";
export default {
  name: "Lesson23",
  mixins: [lessonMixin],
  components: {
    SyllabusPageA,
    WhiteBoard,
    LessonPage,
    RankMapPageOne,
    TargetPage,
    OpeningSongPage,
    IntroductionPage,
    StoryPageOne,
    RecognitionPage_d,
    RecognitionPage_t,
    BingoGame,
    StoryPageTwo,
    RecognitionPage_n,
    RecognitionPage_l,
    PinyinWordsCard,
    BasketballGame,
    StoryPageThree,
    TigerMachineGame,
    RobotJumpGame,
    PinyinMoveGame,
    WritingPage,
    PuzzleGame,
    PinyinSummary,
    RankMapPageTwo,
    ByePage,
  },
  data() {
    return {
      componentsList: {
        LessonPage,
        RankMapPageOne,
        TargetPage,
        OpeningSongPage,
        IntroductionPage,
        StoryPageOne,
        RecognitionPage_d,
        RecognitionPage_t,
        BingoGame,
        StoryPageTwo,
        RecognitionPage_n,
        RecognitionPage_l,
        BasketballGame,
        PinyinWordsCard,
        StoryPageThree,
        TigerMachineGame,
        RobotJumpGame,
        PinyinMoveGame,
        WritingPage,
        PuzzleGame,
        PinyinSummary,
        RankMapPageTwo,
        ByePage,
      },
    };
  },
};
</script>
>

<style lang="scss" scoped>
.lesson-23-container {
  width: 100%;
  height: 100%;
  .whiteBoard-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    line-height: 1;
  }

  // .page-item {
  //   width: 100%;
  //   height: 100%;

  .display-page {
    width: 100%;
    height: 100%;
    // background-color: #fff;
    border-radius: 56px;
  }
  .test {
    background: #fff;
  }

  // }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  // transform: translateX(10px);
  opacity: 0;
}
</style>
