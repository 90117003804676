<!--
 * @Author: your name
 * @Date: 2022-03-11 13:19:36
 * @LastEditTime: 2022-03-15 16:01:33
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter3\Lesson23\19_WritingPage.vue
-->
<template>
  <div class="write-page-heng-container">
    <WritePinyinPage
      :pinyinImgList="pinyinImgList"
      :pinyinList="pinyinList"
      :strokeTimesList="strokeTimesList"
      :buttonList="buttonList"
    />
  </div>
</template>
<script>
import WritePinyinPage from "@/components/Course/CoursePage/WritePinyinPage";

export default {
  data() {
    return {
      pinyinList: ["d", "t", "n", "l"], //ü
      strokeTimesList: [2, 2, 2, 1,],
      pinyinImgList:[
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-03/pinyin-d-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-03/chapter-03-d.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-03/pinyin-t-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-03/chapter-03-t.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-03/pinyin-n-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-03/chapter-03-n.gif'),
        },
        {
           pinyinImg: require('@/assets/img/14-Pinyin/chapter-03/pinyin-l-gif.svg'),
           tipsImg: require('@/assets/img/14-Pinyin/chapter-03/chapter-03-l.gif'),
        },
        
      ],
      buttonList: [
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-d.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-t.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-n.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/14-Pinyin/menu/pinyin-l.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
      ],
    };
  },
  components: {
    WritePinyinPage,
  },
};
</script>
<style lang="scss" scoped>
.write-page-heng-container {
  width: 100%;
  height: 100%;
}
</style>
