<template>
  <div class="game-container">
    <PinyinPuzzleGame :optionList="optionList" :answerList="answerList"/>
  </div>
</template>
<script>
import PinyinPuzzleGame from "@/components/Course/GamePage/PinyinPuzzleGame";

export default {
  components: {
    PinyinPuzzleGame,
  },
  data() {
    return {
      optionList: [
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/yellow-e2.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/yellow-a4.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/yellow-o3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/red-a1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/red-i3.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/red-i2.svg"),
            showObj: true,
          },
        ],
       
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/red-u4.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/red-u1.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/red-o4.svg"),
            showObj: true,
          },
          
        ],
         [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/red-a1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/red-i3.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/red-u4.svg"),
            showObj: true,
          },
          
        ],
      ],

      answerList: [
        {
          id: 2,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/da4.mp3"),
          isCorrect: false,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/white-d.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/white-a4.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/puzzle-character-da.svg"),
        },
        {
          id: 3,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/li2.mp3"),
          isCorrect: false,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/white-l.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/white-i2.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/puzzle-character-li.svg"),
        },
       
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/nu4.mp3"),
          isCorrect: false,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/white-n.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/white-u4.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/puzzle-character-nu.svg"),
        },
        {
          id: 3,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/tu4.mp3"),
          isCorrect: false,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/white-t.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/white-u4.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/lesson-23/puzzle-character-tu.svg"),
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.game-container {
  width: 100%;
  height: 100%;
}
</style>
